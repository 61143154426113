import React from 'react'
import About from '../component/About'

const index = () => {
  return (
    <div>
      <About/>
    </div>
  )
}

export default index
